import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import t, { _t } from '../../utils/translate';
import styles from './SubpageLoader.module.scss';
import Subpage from './Subpage/Subpage';

export default function SubpageLoader({
  edges,
  dark,
  headline,
  loadFrom,
  emptyListText,
}) {
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [currentSubpages, setCurrentSubpages] = useState(edges.slice(0, 4));
  const [sliceIndex, setSliceIndex] = useState(4);

  useEffect(() => {
    if (edges.length > 4) {
      setShowLoadMore(true);
    }
  }, [edges.length]);

  const allIsLoaded = () => {
    if (sliceIndex + 4 > edges.length) {
      setShowLoadMore(false);
    }
  };

  const loadMore = () => {
    let nextSliceIndex = sliceIndex + 4;
    setCurrentSubpages(edges.slice(0, nextSliceIndex));
    setSliceIndex(nextSliceIndex);
    allIsLoaded();
  };

  if (edges.length === 0 && !emptyListText.nb) {
    return null;
  } else {
    return (
      <div className={classNames(styles.outer, dark ? styles.dark : null)}>
        <div className={styles.inner}>
          <h2 className={classNames(styles.headline, 'headline-40')}>
            {headline}
          </h2>
          {edges.length > 0 ? (
            <div className={styles.subpageWrapper}>
              {currentSubpages.map((subpage, index) => {
                return (
                  <Subpage
                    key={index}
                    loadFrom={loadFrom}
                    dark={dark}
                    node={subpage}
                  />
                );
              })}
            </div>
          ) : (
            <p className={'body-text'}>{_t(emptyListText)}</p>
          )}

          {showLoadMore && (
            <div className={styles.loadMoreOuter}>
              <button
                className={styles.loadMoreButton}
                onClick={() => loadMore()}
              >
                + {t('loadMore')}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
