import React from 'react';
import { _t } from '../../utils/translate';
import classNames from 'classnames';
import { useSpring, animated, config } from 'react-spring';

import styles from './PageHeader.module.scss';
import ImageOrVideo from '../ImageOrVideo/ImageOrVideo';
import Shortcuts from '../Shortcuts/Shortcuts';

export default function PageHeader({
  title,
  intro,
  asset = null,
  shortcuts,
  dark,
}) {
  const headProps = useSpring({
    opacity: 1,
    transform: `translate3d(0px, 0px, 0px)`,
    from: {
      opacity: 0,
      transform: `translate3d(0px, 50px, 0px)`,
    },
    config: config.slow,
  });

  const subProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 50px, 0px)' },
    config: config.slow,
    delay: 300,
  });

  return (
    <div className={styles.headerOuter}>
      <div className={styles.headerInner}>
        <div className={styles.textOuter}>
          <div
            className={classNames(
              styles.textInner,
              dark ? styles.dark : undefined
            )}
          >
            <animated.div style={headProps}>
              <h1 className={classNames(styles.headline, 'headline-100')}>
                {_t(title)}
              </h1>
            </animated.div>
            <animated.div style={subProps} className={styles.introOuter}>
              <p className={classNames(styles.ingress, 'ingress')}>
                {_t(intro)}
              </p>
            </animated.div>
          </div>
        </div>
        {asset && (
          <div className={styles.assetOuter}>
            <animated.div style={headProps}>
              {asset.map((content, index) => {
                return <ImageOrVideo key={index} content={content} />;
              })}
            </animated.div>
          </div>
        )}
      </div>
      {shortcuts && <Shortcuts dark={dark} shortcuts={shortcuts} />}
    </div>
  );
}
