import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Blocks from '../Blocks/Blocks';
import styles from './TwoColumns.module.scss';

export default function TwoColumns({ contents, location, dark }) {
  const [width, setWidth] = useState();
  // console.log('props', contents);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', setWindowSize);
    return () => {
      window.removeEventListener('resize', setWindowSize);
    };
  }, []);

  const setWindowSize = () => {
    setWidth(window.innerWidth);
  };

  if (width > 768) {
    return (
      <div className={classNames(styles.twocolumnsOuter)}>
        <div className={classNames(styles.column, styles.columnEven)}>
          {contents.map((content, index) => {
            if (index % 2 === 0) {
              return (
                <Blocks
                  dark={dark}
                  location={location}
                  index={index}
                  key={content.id || content._key}
                  {...content}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <div className={classNames(styles.column, styles.columnOdd)}>
          {contents.map((content, index) => {
            if (index % 2 === 1) {
              return (
                <Blocks
                  dark={dark}
                  location={location}
                  index={index}
                  key={content.id || content._key}
                  {...content}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.twocolumnsOuter}>
        <div className={classNames(styles.column, styles.mobile)}>
          {contents.map((content, index) => {
            return (
              <Blocks
                dark={dark}
                location={location}
                index={index}
                key={content.id || content._key}
                {...content}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
