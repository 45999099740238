import React from 'react';
import Img from 'gatsby-image';
import _ from 'lodash';
import classNames from 'classnames';

import t, { _t } from '../../../utils/translate';
import { fluidImagePropHelper } from '../../../utils/imagePropHelper';

import Vector from '../../Vector/Vector';
import LocaleLink from '../../LocaleLink/LocaleLink';
import styles from './ContentBlock.module.scss';

export default function ContentBlock({
  imageOrientation,
  __typename,
  headline,
  slug,
  intro,
  eventStart,
  eventEnd,
  index,
  dark,
  shortTitle,
  toggleShortTitle,
  imageOrVideo,
  linkText,
}) {
  let url = '';
  switch (__typename) {
    case 'SanitySubpageVisit':
      url = 'besok';
      break;

    case 'SanitySubpageExperiences':
    case 'SanitySubpageEvents':
      url = 'opplevelser';
      break;

    default:
      url = '';
  }

  let aspectRatio;
  let odd;
  odd = index % 2 === 0 ? (odd = true) : (odd = false);

  odd
    ? (aspectRatio = imageOrientation ? 'odd-tall' : 'odd')
    : (aspectRatio = imageOrientation ? 'even-tall' : 'even');

  return (
    <div
      className={classNames(styles.blockOuter)}
      id={_.camelCase(toggleShortTitle ? _t(shortTitle) : _t(headline))}
    >
      <LocaleLink to={`/${url}/${slug.current}`}>
        {imageOrVideo && (
          <div className="parallax">
            <div
              className={classNames(
                styles.imageOuter,
                imageOrientation ? styles.tall : null,
                'parallax__layer parallax__layer--back'
              )}
            >
              {imageOrVideo.map(media => {
                const { _type, asset, _key, alt, videoPoster } = media;
                let imgProp;

                if (_type === 'mediaImage') {
                  if (imageOrientation) {
                    imgProp = fluidImagePropHelper(
                      asset.id,
                      odd ? 800 : 600,
                      780
                    );
                  } else {
                    if (odd) {
                      imgProp = fluidImagePropHelper(asset.id, 800, 530);
                    } else {
                      imgProp = fluidImagePropHelper(asset.id, 660, 420);
                    }
                  }
                  return <Img alt={alt} key={_key} fluid={imgProp} />;
                } else if (_type === 'videoUpload') {
                  return (
                    <div
                      key={_key}
                      className={classNames(
                        styles.videoOuter,
                        'responsive-video',
                        aspectRatio
                      )}
                    >
                      <video
                        className={styles.video}
                        autoPlay
                        loop
                        muted
                        playsInline
                        controls={false}
                        src={asset.url}
                        poster={
                          videoPoster ? videoPoster.asset.fixed.src : null
                        }
                      >
                        {t('videoAlert')}
                      </video>
                    </div>
                  );
                } else {
                  return null;
                }
              })}

              {eventStart || eventEnd ? (
                <div className={classNames(styles.eventCont, 'body-text-s')}>
                  <span className={styles.event}>
                    {eventStart} - {eventEnd}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <div
          className={classNames(
            styles.textOuter,
            dark ? styles.dark : undefined
          )}
        >
          {headline && (
            <h2 className={classNames(styles.headline, 'headline-40')}>
              {_t(headline)}
            </h2>
          )}
          <p className="body-text-s">{_t(intro)}</p>
          <div className={styles.readMoreOuter}>
            <Vector
              type={'arrow-right'}
              className={styles.arrow}
              colour={'#007788'}
            />
            {linkText && linkText.nb && linkText.en ? (
              <p className="body-text-s">{_t(linkText)}</p>
            ) : (
              <p className="body-text-s">{t('readMore')}</p>
            )}
          </div>
        </div>
      </LocaleLink>
    </div>
  );
}
