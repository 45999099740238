import React from 'react';

import styles from './Blocks.module.scss';
import InfoBlock from './InfoBlock/InfoBlock';
import ContentBlock from './ContentBlock/ContentBlock';

const Blocks = content => {
  const { __typename } = content;

  // const evenAndSmall = index => {
  //   if (index % 4 === 2) return true;
  //   return false;
  // };

  if (__typename === 'SanityInfoBlocks') {
    return (
      <div className={styles.blockOuter}>
        <InfoBlock {...content} />
      </div>
    );
  } else {
    return (
      <div className={styles.blockOuter}>
        <ContentBlock {...content} />
      </div>
    );
  }
};

export default Blocks;
