import React from 'react';
import { graphql } from 'gatsby';

import t, { _t } from '../utils/translate';
import Layout from '../components/Layout/Layout';
import PageHeader from '../components/PageHeader/PageHeader';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import SubpageLoader from '../components/SubpageLoader/SubpageLoader';
import SEO from '../components/seo';
import useSetLocale from '../hooks/useSetLocale';

const Experiences = ({ data, location }) => {
  useSetLocale();

  const {
    experienceContent,
    imageOrVideo,
    intro,
    title,
    noEventText,
    eventList,
    seo,
  } = data.sanityExperiences;
  const dark = true;

  return (
    <Layout dark={dark} location={location} seo={seo}>
      <SEO
        title={`${_t(title)} | SeeSalmon`}
        description={seo && seo.description ? seo.description : null}
        image={seo && seo.image ? seo.image.asset.fixed.src : null}
      />
      <PageHeader
        dark={dark}
        title={title}
        intro={intro}
        asset={imageOrVideo}
        shortcuts={experienceContent}
      />
      <SubpageLoader
        dark={dark}
        headline={t('eventHeadline')}
        loadFrom="event"
        emptyListText={noEventText}
        edges={eventList}
      />
      <div className="page-padding">
        <TwoColumns
          dark={dark}
          location={location}
          contents={experienceContent}
        />
      </div>
    </Layout>
  );
};

export default Experiences;

export const query = graphql`
  query {
    sanityExperiences {
      title {
        en
        nb
        _type
      }
      intro {
        nb
        en
        _type
      }
      imageOrVideo {
        ... on SanityVideoUpload {
          ...HdVideoQuery
        }
        ... on SanityMediaImage {
          ...HeadImageQuery
        }
      }
      noEventText {
        nb
        en
        _type
      }
      eventList {
        title
        id
        slug {
          current
        }
        intro {
          nb
          en
          _type
        }
        headline {
          nb
          en
          _type
        }
        imageOrVideo {
          ... on SanityVideoUpload {
            ...HdVideoQuery
          }
          ... on SanityMediaImage {
            _key
            _type
            alt
            asset {
              fluid(maxWidth: 380, maxHeight: 275) {
                ...GatsbySanityImageFluid
              }
              url
              id
            }
          }
        }
        eventStart(formatString: "DD.MM.YYYY")
        eventEnd(formatString: "DD.MM.YYYY")
      }
      experienceContent {
        ... on SanitySubpageExperiences {
          ...ExperiencesPageQuery
        }
        ... on SanitySubpageEvents {
          ...EventPageQuery
        }
        ... on SanityInfoBlocks {
          ...SanityInfoBlockQuery
        }
      }
      seo {
        ...SeoQuery
      }
    }
  }
`;
