import React from 'react';
import { _t } from '../../utils/translate';
import _ from 'lodash';
import classNames from 'classnames';

import styles from './Shortcuts.module.scss';
import Vector from '../Vector/Vector';

export default function Shortcuts({ shortcuts, dark }) {
  return (
    <div
      className={classNames(
        styles.shortcutsOuter,
        dark ? styles.dark : undefined
      )}
    >
      <ul className={styles.listOuter}>
        <li className={styles.arrowOuter}>
          <Vector
            type={'arrow-down'}
            colour={'#FFC600'}
            className={styles.arrowInner}
          />
        </li>

        {shortcuts.map(content => {
          return (
            <li key={content.id || content._key}>
              <a
                href={`#${_.camelCase(
                  content.toggleShortTitle
                    ? _t(content.shortTitle)
                    : _t(content.headline)
                )}`}
              >
                <p>
                  {content.toggleShortTitle
                    ? _t(content.shortTitle)
                    : _t(content.headline)}
                </p>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
