import React from 'react';
import { useSpring, animated } from 'react-spring';
import Img from 'gatsby-image';
import classNames from 'classnames';
import styles from './Subpage.module.scss';
import t, { _t } from '../../../utils/translate';
import { fluidImagePropHelper } from '../../../utils/imagePropHelper';

import LocaleLink from '../../LocaleLink/LocaleLink';

export default function Subpage({ node, dark, loadFrom }) {
  const { headline, intro, imageOrVideo, slug, eventStart, eventEnd } = node;
  // const shortIntro =
  //   intro.nb && intro.en ? _t(intro).substring(0, 124).concat('…') : null;
  const props = useSpring({
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 50px, 0px)' },
  });

  let url;

  switch (loadFrom) {
    case 'learning':
      url = `/besok/undervisning/`;
      break;

    case 'event':
      url = `/opplevelser/`;
      break;

    default:
      break;
  }

  return (
    <LocaleLink
      className={classNames(styles.outer, dark ? styles.dark : null)}
      to={url + slug.current}
    >
      <animated.div style={props} className={styles.inner}>
        <div className={styles.imageWrapper}>
          {imageOrVideo.map(media => {
            const { _type, alt, asset, _key, videoPoster } = media;
            if (_type === 'mediaImage') {
              const imageProp =
                loadFrom === 'learning'
                  ? fluidImagePropHelper(asset.id, 380, 274)
                  : null;
              return (
                <Img
                  alt={alt}
                  key={_key}
                  fluid={loadFrom === 'learning' ? imageProp : asset.fluid}
                />
              );
            } else if (_type === 'videoUpload') {
              return (
                <div
                  key={_key}
                  className={classNames(styles.videoOuter, 'responsive-video')}
                >
                  <video
                    className={styles.video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls={false}
                    src={asset.url}
                    poster={videoPoster ? videoPoster.asset.fixed.src : null}
                  >
                    {t('videoAlert')}
                  </video>
                </div>
              );
            } else {
              return null;
            }
          })}
          {eventStart || eventEnd ? (
            <div className={styles.dates}>
              <span className={styles.event}>
                {eventStart && eventStart} {eventEnd && '- ' + eventEnd}
              </span>
            </div>
          ) : null}
        </div>
        <h3 className={classNames(styles.headline, 'headline-30')}>
          {_t(headline)}
        </h3>
        <p className={classNames(styles.text, 'body-text-s')}>{_t(intro)}</p>
      </animated.div>
    </LocaleLink>
  );
}
